import { makeStyles, ThemeProvider } from "@material-ui/core";
import { WalletProvider } from "contexts/wallet";
import { ContractsProvider } from "contexts/contracts";
import { NotificationsProvider } from "contexts/notifications";
import { SnackbarProvider } from "notistack";

import Header from "components/Header";
import Notification from "components/Notification";

import DeployPage from "pages/DeployPage";

import theme from "./theme";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    top: 70,
  },
}));


function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <WalletProvider>
        <ContractsProvider>
          <SnackbarProvider
            classes={{ root: classes.snackbar }}
            maxSnack={4}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            content={(key, data) =>
              <div>
                <Notification id={key} notification={data}/>
              </div>
            }
          >
            <NotificationsProvider>
              <Header/>
              <DeployPage/>
            </NotificationsProvider>
          </SnackbarProvider>
        </ContractsProvider>
      </WalletProvider>
    </ThemeProvider>
  );
}

export default App;
