import React, { useEffect } from "react";
import {
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { useWallet } from "contexts/wallet";


const useStyles = makeStyles((theme) => ({
  selector: {
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "4px",
    padding: "0px 8px 0 4px",
    minWidth: "120px",
    "& .MuiSelect-icon": {
      color: "#fff",
    }
  },
}));

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function NetworkSelector() {
  const classes = useStyles();
  
  const { network, switchNetwork } = useWallet();

  const handleChange = (e) => {
    switchNetwork(e.target.value);
  };

  useEffect(() => {
  }, [network]);
  
  return (
    <Select
      className={classes.selector}
      labelId="network"
      id="network"
      value={network || "mainnet"}
      label="Network"
      onChange={handleChange}
      renderValue={(value) => !value || value === "unknown" ? "⚠️ Unknown" : capitalize(value)}
    >
      <MenuItem value={"mainnet"}>Mainnet</MenuItem>
      <MenuItem value={"sepolia"}>Sepolia</MenuItem>
      <MenuItem value={"base"}>Base</MenuItem>
    </Select>
  );
}