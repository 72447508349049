export const SUPPORTED_NETWORK_NAMES = ["mainnet", "sepolia", "base"];

export const MEMECOIN_DEPLOYER_ADDRESSES = {
  "mainnet": "0x2EeBaA90daD28D522ef8e49ECFC3Ad35383dEB28",
  "base": "0x6Ce28A2Ad10a48c739C44FbADE7978979b2e0f0a",
  "sepolia": "0x39F222e2cb80C659016Aeb2dFF691c91342a9d2F",
}

export const WETH_ADDRESSES = {
  "mainnet": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  "base": "0x4200000000000000000000000000000000000006",
  "sepolia": "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
}

export const NETWORK_NAME_BY_CHAIN_ID = {
  1: "mainnet",
  8453: "base",
  11155111: "sepolia",
}

export const NETWORK_PARAMS_BY_CHAIN_ID = {
  1: {
    "chainId": "0x1",
    "chainName": "Ethereum Mainnet",
    "rpcUrls": [ "https://mainnet.infura.io/v3/" ],
    "nativeCurrency": {
      "name": "ETH",
      "symbol": "ETH",
      "decimals": 18
    },    
    "blockExplorerUrls": ["https://etherscan.io"],
  },
  8453: {
    "chainId": "0x2105",
    "chainName": "Base Mainnet",
    "rpcUrls": [ "https://mainnet.base.org" ],
    "nativeCurrency": {
      "name": "ETH",
      "symbol": "ETH",
      "decimals": 18
    },    
    "blockExplorerUrls": ["https://explorer.base.org"],
  },
  11155111: {
    "chainId": "0xaa36a7",
    "chainName": "Sepolia test network",
    "rpcUrls": [ "https://sepolia.infura.io/v3/" ],
    "nativeCurrency": {
      "name": "SepoliaETH",
      "symbol": "SepoliaETH",
      "decimals": 18
    },    
    "blockExplorerUrls": ["https://sepolia.etherscan.io"],
  },
}

export function chainIdByNetworkName(networkName) {
  return Object.keys(NETWORK_NAME_BY_CHAIN_ID).find(chainId => NETWORK_NAME_BY_CHAIN_ID[chainId] === networkName);
}
