import { useCallback, useEffect, useState } from 'react';

import { useContracts } from 'contexts/contracts';
import { useNotifications } from 'contexts/notifications';
import { ethers } from 'ethers';
import { getSqrtRatioAtTick, upperSpacingTickAtFee } from 'utils/utils';

const useMemecoinDeployer = () => {
  const { memecoinDeployer } = useContracts();
  const [isDeploying, setIsDeploying] = useState(false);
  const { tx } = useNotifications();

  useEffect(() => {}, [memecoinDeployer]);

  const deploy = useCallback(
    async (params, onSuccess = () => {}) => {
      if (!memecoinDeployer) {
        alert("Unsupported network");
      }
      try {
        const tokenName = params.tokenName;
        const tokenSymbol = params.tokenSymbol;
        const initialSupply = ethers.BigNumber.from(params.initialSupply);
        const poolFee = params.poolFee;
        const tickLower = params.tick;
        const tickUpper = upperSpacingTickAtFee(params.poolFee);
        const sqrtPriceX96 = ethers.BigNumber.from(getSqrtRatioAtTick(tickLower).toString());
        setIsDeploying(true);
        await tx('Deploying...', 'Deployed!', () =>
          memecoinDeployer.deploy(tokenName, tokenSymbol, initialSupply, sqrtPriceX96, poolFee, tickLower, tickUpper)
        );
        onSuccess && onSuccess();
      } catch (e) {
        console.warn(e)
      } finally {
        setIsDeploying(false);
      }
    },
    [memecoinDeployer, tx],
  );

  return {
    isDeploying,
    deploy,
  }
}

export default useMemecoinDeployer;