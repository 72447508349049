import React from "react";
import {
  AppBar,
  Button,
  Container,
  Link,
  Grid,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useWallet } from "contexts/wallet";
import { shortAddress } from "utils/formatters";

import NetworkSelector from "components/NetworkSelector"


const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: 70,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  logo: {
    display: "block",
  },
  logoLink: {
    textDecoration: "none",
  },
  logoText: {
      color: "#fff",
      position: "relative",
      display: "inline-block",
  },
  connectButton: {
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
  },
  toolbarLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 0,
  },
  toolbarRight: {
    display: "flex",
  },
}));

export default function Header() {
  const classes = useStyles();
 
  const { userAddress, connectWallet } = useWallet();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <div className={classes.logo}>
              <Link className={classes.logoLink} to="/">
                <Typography variant="h5" noWrap>
                  <span className={classes.logoText}>MemeCoin Factory</span>
                </Typography>
              </Link>
            </div>
          </div>

          <div className={classes.toolbarRight}>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
              <Grid item>
                {userAddress ? <NetworkSelector/> : null}
              </Grid>
              <Grid item>
                {userAddress ? (
                  <div className={classes.account}>{shortAddress(userAddress)}</div>
                ) : (
                  <Button className={classes.connectButton} color="secondary" onClick={connectWallet}>
                    Connect
                  </Button>
                )}
              </Grid>
            </Grid>            
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}