import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import {
  ArrowUpward as TxIcon,
  Done as SuccessIcon,
  Clear as ErrorIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useWallet } from 'contexts/wallet';
import { NETWORK_PARAMS_BY_CHAIN_ID, WETH_ADDRESSES, chainIdByNetworkName } from 'config';
import { useContracts } from 'contexts/contracts';

const useStyles = makeStyles((theme) => ({
  paper: {
    color: 'white',
  },
  container: {
    padding: '10px 20px 10px 10px',
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    '& a': {
      color: 'white',
      display: 'block',
      textDecoration: 'underline',
    },
    minWidth: '240px',    
  },
  contentContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    marginRight: 10,
    display: 'inline-flex',
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'pointer',
  },
  tx: {
    background: '#2196f3',
  },
  error: {
    background: '#d32f2f',
  },
  success: {
    background: '#43a047',
  },
  small: {
    fontSize: 16,
  },
}));

const Notification = ({id, notification}) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const clearNotification = () => closeSnackbar(id);

  const TYPES = {
    'tx': [TxIcon, TxContent],
    'error': [ErrorIcon, ErrorContent],
    'success': [SuccessIcon, SuccessContent],
  };

  const [, Content] = TYPES[notification.type];

  const notificationClass = useMemo(() => {
    if (notification.status === 'success') {
        return classes.success;
    }
    const c = {
      tx: classes.tx,
      error: classes.error,
      success: classes.success,
    };
    return c[notification.type];
  }, [notification.type, notification.status, classes.error, classes.success, classes.tx]);

  return (
    <Paper className={clsx(classes.paper, notificationClass)}>
      <div className={classes.close} onClick={clearNotification}>
        <CloseIcon style={{ fontSize: 15 }} />
      </div>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <Content {...{ notification }} />
        </div>
      </div>
    </Paper>
  );
};

const TxContent = ({ notification }) => {
  const classes = useStyles();
  const { network } = useWallet();
  const { getDeployedTokenFromLogs } = useContracts();

  useEffect(() => {}, [network, getDeployedTokenFromLogs]);

  const chainId = chainIdByNetworkName(network);
  const networkParams = NETWORK_PARAMS_BY_CHAIN_ID[chainId];
  const blockExplorerUrl = networkParams["blockExplorerUrls"][0];
  const wethAddress = WETH_ADDRESSES[network];

  let linkTitle = null;
  let link = null;
  const tokenAddress = notification.result;
  if (notification.status === 'success' && tokenAddress) {
    linkTitle = "Trade on Uniswap";
    link = `https://app.uniswap.org/swap?chain=${network}&inputCurrency=ETH&outputCurrency=${tokenAddress}`;
  } else {
    linkTitle = "View on Explorer";
    link = `${blockExplorerUrl}/tx/${notification.hash}`;
  }

  return (
    <>
      <strong style={{'marginBottom': '8px'}} className={classes.small}>{notification.description}</strong>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        className={classes.small}
      >
        {linkTitle}
      </a>
    </>
  );
};

const ErrorContent = ({ notification }) => {
  const classes = useStyles();
  return (
    <>
      <strong className={clsx(classes.small, classes.error)}>
        {notification.message}
      </strong>
    </>
  );
};

const SuccessContent = ({ notification }) => {
  const classes = useStyles();
  return (
    <>
      <div style={{'marginBottom': '8px'}}>{notification.title}</div>
      <strong className={clsx(classes.small, classes.success)}>
        {notification.message}
      </strong>
    </>
  );
};

export default Notification;