import React from 'react';
import { useContext, createContext } from 'react';
import { useSnackbar } from 'notistack';
import { ethers } from "ethers";
import { WETH_ADDRESSES } from 'config';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showTxNotification = (description, hash, status, result) => {
    enqueueSnackbar(
      { type: 'tx', description, hash, status, result },
      {
        persist: true,
      }
    );
  };

  const showErrorNotification = (msg) => {
    enqueueSnackbar(
      {
        type: 'error',
        message: msg?.error?.message || msg.responseText || msg.message || msg,
      },
      {
        persist: true,
      }
    );
  };

  const showSuccessNotification = (title, message) => {
    enqueueSnackbar(
      {
        type: 'success',
        title,
        message,
      },
      {
        persist: true,
      }
    );
  };

  const tx = async (
    startNotification,
    endNotification,
    makeTx
  ) => {
    try {
      const { hash, wait } = await makeTx();
      showTxNotification(startNotification, hash);
      const receipt = await wait();
      if (receipt.status !== 1) {
        showErrorNotification("Transaction failed");
        return;
      }
      let tokenAddress = null;
      if (receipt.logs) {
        const poolCreatedEventABI = [ "event PoolCreated(address indexed token0, address indexed token1, uint24 indexed fee, int24 tickSpacing, address pool)" ];
        const poolCreatedInterface = new ethers.utils.Interface(poolCreatedEventABI);
        receipt.logs.forEach((log) => {
          try {
            const parsedLog = poolCreatedInterface.parseLog(log);
            if (Object.values(WETH_ADDRESSES).indexOf(parsedLog.args.token0) !== -1) {
              tokenAddress = parsedLog.args.token1;
            } else {
              tokenAddress = parsedLog.args.token0;
            }
          } catch (error) {}
        });      
      }
      showTxNotification(endNotification, hash, "success", tokenAddress);
    } catch (e) {
      showErrorNotification(e.reason || e.message);
      throw e;
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        showTxNotification,
        showErrorNotification,
        showSuccessNotification,
        tx,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('Missing Notifications context');
  }
  const {
    showTxNotification,
    showErrorNotification,
    showSuccessNotification,
    tx,
  } = context;
  return {
    showTxNotification,
    showErrorNotification,
    showSuccessNotification,
    tx,
  };
}