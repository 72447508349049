import BigNumber from 'bignumber.js';
import JSBI from 'jsbi';

const TICK_SPACING = {
  500: 10,
  3000: 60,
  10000: 200,
}
const MIN_TICK = -887272;
const MAX_TICK = -MIN_TICK;
const MIN_SQRT_RATIO = JSBI.BigInt('4295128739');
const MAX_SQRT_RATIO = JSBI.BigInt('1461446703485210103287273052203988822378723970342');
const Q32 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(32));
const Q192 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(192));
const ZERO = JSBI.BigInt(0);
const ONE = JSBI.BigInt(1);
const TWO = JSBI.BigInt(2);
const POWERS_OF_2 = [128, 64, 32, 16, 8, 4, 2, 1].map(function (pow) {
  return [pow, JSBI.exponentiate(TWO, JSBI.BigInt(pow))];
});
const MaxUint256 = JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');


function invariant(condition, msg) {
  if (!condition) throw Error(msg);
}

function mulShift(val, mulBy) {
  return JSBI.signedRightShift(JSBI.multiply(val, JSBI.BigInt(mulBy)), JSBI.BigInt(128));
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _createForOfIteratorHelperLoose(o, allowArrayLike) {
  var it;

  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;
      return function () {
        if (i >= o.length) return {
          done: true
        };
        return {
          done: false,
          value: o[i++]
        };
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  it = o[Symbol.iterator]();
  return it.next.bind(it);
}

function mostSignificantBit(x) {
  !JSBI.greaterThan(x, ZERO) ?  invariant(false, 'ZERO')  : void 0;
  !JSBI.lessThanOrEqual(x, MaxUint256) ?  invariant(false, 'MAX')  : void 0;
  var msb = 0;

  for (var _iterator = _createForOfIteratorHelperLoose(POWERS_OF_2), _step; !(_step = _iterator()).done;) {
    var _step$value = _step.value,
        power = _step$value[0],
        min = _step$value[1];

    if (JSBI.greaterThanOrEqual(x, min)) {
      x = JSBI.signedRightShift(x, JSBI.BigInt(power));
      msb += power;
    }
  }
  return msb;
}

export function getSqrtRatioAtTick(tick) {
  !(tick >= MIN_TICK && tick <= MAX_TICK && Number.isInteger(tick)) ?  invariant(false, 'TICK')  : void 0;
  var absTick = tick < 0 ? tick * -1 : tick;
  var ratio = (absTick & 0x1) != 0 ? JSBI.BigInt('0xfffcb933bd6fad37aa2d162d1a594001') : JSBI.BigInt('0x100000000000000000000000000000000');
  if ((absTick & 0x2) != 0) ratio = mulShift(ratio, '0xfff97272373d413259a46990580e213a');
  if ((absTick & 0x4) != 0) ratio = mulShift(ratio, '0xfff2e50f5f656932ef12357cf3c7fdcc');
  if ((absTick & 0x8) != 0) ratio = mulShift(ratio, '0xffe5caca7e10e4e61c3624eaa0941cd0');
  if ((absTick & 0x10) != 0) ratio = mulShift(ratio, '0xffcb9843d60f6159c9db58835c926644');
  if ((absTick & 0x20) != 0) ratio = mulShift(ratio, '0xff973b41fa98c081472e6896dfb254c0');
  if ((absTick & 0x40) != 0) ratio = mulShift(ratio, '0xff2ea16466c96a3843ec78b326b52861');
  if ((absTick & 0x80) != 0) ratio = mulShift(ratio, '0xfe5dee046a99a2a811c461f1969c3053');
  if ((absTick & 0x100) != 0) ratio = mulShift(ratio, '0xfcbe86c7900a88aedcffc83b479aa3a4');
  if ((absTick & 0x200) != 0) ratio = mulShift(ratio, '0xf987a7253ac413176f2b074cf7815e54');
  if ((absTick & 0x400) != 0) ratio = mulShift(ratio, '0xf3392b0822b70005940c7a398e4b70f3');
  if ((absTick & 0x800) != 0) ratio = mulShift(ratio, '0xe7159475a2c29b7443b29c7fa6e889d9');
  if ((absTick & 0x1000) != 0) ratio = mulShift(ratio, '0xd097f3bdfd2022b8845ad8f792aa5825');
  if ((absTick & 0x2000) != 0) ratio = mulShift(ratio, '0xa9f746462d870fdf8a65dc1f90e061e5');
  if ((absTick & 0x4000) != 0) ratio = mulShift(ratio, '0x70d869a156d2a1b890bb3df62baf32f7');
  if ((absTick & 0x8000) != 0) ratio = mulShift(ratio, '0x31be135f97d08fd981231505542fcfa6');
  if ((absTick & 0x10000) != 0) ratio = mulShift(ratio, '0x9aa508b5b7a84e1c677de54f3e99bc9');
  if ((absTick & 0x20000) != 0) ratio = mulShift(ratio, '0x5d6af8dedb81196699c329225ee604');
  if ((absTick & 0x40000) != 0) ratio = mulShift(ratio, '0x2216e584f5fa1ea926041bedfe98');
  if ((absTick & 0x80000) != 0) ratio = mulShift(ratio, '0x48a170391f7dc42444e8fa2');
  if (tick > 0) ratio = JSBI.divide(MaxUint256, ratio); // back to Q96

  return JSBI.greaterThan(JSBI.remainder(ratio, Q32), ZERO) ? JSBI.add(JSBI.divide(ratio, Q32), ONE) : JSBI.divide(ratio, Q32);
}

export function getTickAtSqrtRatio(sqrtRatioX96) {
  !(JSBI.greaterThanOrEqual(sqrtRatioX96, MIN_SQRT_RATIO) && JSBI.lessThan(sqrtRatioX96, MAX_SQRT_RATIO)) ?  invariant(false, 'SQRT_RATIO')  : void 0;
  var sqrtRatioX128 = JSBI.leftShift(sqrtRatioX96, JSBI.BigInt(32));
  var msb = mostSignificantBit(sqrtRatioX128);
  var r;

  if (JSBI.greaterThanOrEqual(JSBI.BigInt(msb), JSBI.BigInt(128))) {
    r = JSBI.signedRightShift(sqrtRatioX128, JSBI.BigInt(msb - 127));
  } else {
    r = JSBI.leftShift(sqrtRatioX128, JSBI.BigInt(127 - msb));
  }

  var log_2 = JSBI.leftShift(JSBI.subtract(JSBI.BigInt(msb), JSBI.BigInt(128)), JSBI.BigInt(64));

  for (var i = 0; i < 14; i++) {
    r = JSBI.signedRightShift(JSBI.multiply(r, r), JSBI.BigInt(127));
    var f = JSBI.signedRightShift(r, JSBI.BigInt(128));
    log_2 = JSBI.bitwiseOr(log_2, JSBI.leftShift(f, JSBI.BigInt(63 - i)));
    r = JSBI.signedRightShift(r, f);
  }

  var log_sqrt10001 = JSBI.multiply(log_2, JSBI.BigInt('255738958999603826347141'));
  var tickLow = JSBI.toNumber(JSBI.signedRightShift(JSBI.subtract(log_sqrt10001, JSBI.BigInt('3402992956809132418596140100660247210')), JSBI.BigInt(128)));
  var tickHigh = JSBI.toNumber(JSBI.signedRightShift(JSBI.add(log_sqrt10001, JSBI.BigInt('291339464771989622907027621153398088495')), JSBI.BigInt(128)));
  return tickLow === tickHigh ? tickLow : JSBI.lessThanOrEqual(getSqrtRatioAtTick(tickHigh), sqrtRatioX96) ? tickHigh : tickLow;
};


export function getSqrtRatioByPrice(priceStr) /* JSBI */ {
  // sqrt(price * Q192)
  const price = new BigNumber(priceStr);
  const q192 = new BigNumber(Q192.toString());
  const sqrtPriceX96 = price.multipliedBy(q192).sqrt().toFixed(0);
  return JSBI.BigInt(sqrtPriceX96.toString());
}

export function getPriceBySqrtRatio(sqrtRatioX96) /* string */ {
  // sqrtPriceX96**2 / Q192
  const priceX96 = (new BigNumber(sqrtRatioX96.toString())).exponentiatedBy(2);
  const q192 = new BigNumber(Q192.toString());
  return priceX96.dividedBy(q192).toFixed(18);
}

export function floatStringToJSBI(floatStr) {
  let [i, f] = floatStr.split('.');
  i = i || "0";
  f = (f || "0").substring(0, 18);
  f = f + "0".repeat(18 - f.length);
  return JSBI.BigInt(i + f);
}  

export function roundToClosestTickPrice(priceStr, fee) {
  const sqrtPriceX96 = getSqrtRatioByPrice(priceStr);

  const tickSpacing = TICK_SPACING[fee];
  const tick = getTickAtSqrtRatio(sqrtPriceX96);  
  const tickLower = tick - ((tick % tickSpacing) + tickSpacing) % tickSpacing;

  const sqrtPriceAX96 = getSqrtRatioAtTick(tickLower);
  const roundedPrice = getPriceBySqrtRatio(sqrtPriceAX96);
  return { roundedPrice, tick: tickLower }
}

export function upperSpacingTickAtFee(fee) {
  const tickSpacing = TICK_SPACING[fee];
  return MAX_TICK - MAX_TICK % tickSpacing;
}