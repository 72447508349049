import React, { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { ethers } from "ethers";
import { Backdrop, CircularProgress } from "@material-ui/core";

import { NETWORK_NAME_BY_CHAIN_ID, NETWORK_PARAMS_BY_CHAIN_ID, chainIdByNetworkName } from 'config';

export const WalletContext = createContext({
  network: null,
  signer: null,
  userAddress: '',
  connectWallet: () => {},
});

export const WalletProvider = ({ children }) => {
  const [userAddress, setUserAddress] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [network, setNetwork] = useState();
  const [signer, setSigner] = useState();

  const setProvider = useCallback(
    async (web3Provider) => {
      web3Provider.on("accountsChanged", () => {
        window.location.reload();
      });
      web3Provider.on("chainChanged", () => {
        window.location.reload();
      });

      const provider = new ethers.providers.Web3Provider(web3Provider);
      const networkInfo = await provider.getNetwork();
      const networkName = NETWORK_NAME_BY_CHAIN_ID[networkInfo.chainId] || "unknown";

      const signer = provider.getSigner();

      setNetwork(networkName);
      setSigner(signer);
      setUserAddress(await signer.getAddress());
    },
    []
  );

  const connectWallet = async () => {
    if (!window.ethereum) return;
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      await setProvider(window.ethereum);
    } catch (error) {
      console.error("Error connecting to MetaMask", error);
    }
  }

  const switchNetwork = async (networkName) => {
    if (!window.ethereum || !userAddress) return;
    const chainId = chainIdByNetworkName(networkName);
    const chainIdHex = `0x${Number(chainId).toString(16)}`
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }],
      });  
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [NETWORK_PARAMS_BY_CHAIN_ID[chainId]],
          });  
        } catch (addError) {
          console.log(addError);
        }
      }
    }
  }

  // Optionally, automatically try to connect to the wallet when the component mounts
  useEffect(() => {
    const load = async () => {
      if (userAddress) return;

      if (window.ethereum && window.ethereum.selectedAddress) {
        await connectWallet();
      }
    };

    load().then(() => setIsInitialized(true));
  }, [userAddress, connectWallet]);

  if (!isInitialized) {
    const loader = (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );  
    return <>{loader}</>;
  }
  return (
    <WalletContext.Provider
      value={{
        network,
        signer,
        userAddress,
        connectWallet,
        switchNetwork,
      }}>
      {children}
    </WalletContext.Provider>
  );
};

export function useWallet() {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("Missing wallet context");
  }
  const {
    network,
    signer,
    userAddress,
    connectWallet,
    switchNetwork,
  } = context;

  return {
    network,
    signer,
    userAddress,
    connectWallet,
    switchNetwork,
  };
}