import { React, useEffect, useState } from "react"
import {
  makeStyles,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Grid,
  TextField,
} from "@material-ui/core"
import NumberFormat from 'react-number-format';
import { roundToClosestTickPrice } from "utils/utils.js"
import { useWallet } from "contexts/wallet";

const useStyle = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}))

function NumberFormatCustom(props) {
  const { inputRef, onChange, decimalScale, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // {formattedValue: "12,345.67", value: "12345.67", floatValue: 12345.67}
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString={true}
      allowLeadingZeros={false}
      allowNegative={false}
      decimalScale={decimalScale}
    />
  );
}

const DeployTokenForm = ({ onDeploy, isDeploying }) => {
  const classes = useStyle();

  const [formData, setFormData] = useState({
    tokenName: '',
    tokenSymbol: '',
    initialSupply: '',
    initialPrice: '',
    poolFee: 10000,
    isInitialPriceRounded: false,
    tick: null,
  });
  const [isValid, setIsValid] = useState(false);
  const { userAddress } = useWallet();

  useEffect(() => {
    const validateForm = () => {
      if (formData.tokenName.length === 0) return false;
      if (formData.tokenSymbol.length === 0) return false;
      if (formData.initialSupply.length === 0) return false;
      if (formData.initialPrice.length === 0) return false;
      if (Number(formData.initialSupply) === 0) return false;
      if (Number(formData.initialPrice) === 0) return false;
      return true;
    }
    setIsValid(validateForm());
  }, [formData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (formData[name] === value) return;
    const isInitialPriceRounded = (name === 'initialPrice' ? false : formData.isInitialPriceRounded);
    setFormData({
      ...formData,
      [name]: value,
      'isInitialPriceRounded': isInitialPriceRounded,
    });
  };

  const handleInitialPriceBlur = (e) => {
    if (Number(formData.initialPrice) === 0 || formData.isInitialPriceRounded) return;
    const { name } = e.target;
    const { roundedPrice, tick } = roundToClosestTickPrice(formData.initialPrice, formData.poolFee);
    setFormData({
      ...formData,
      [name]: roundedPrice,
      'isInitialPriceRounded': true,
      'tick': tick,
    });
  }

  const handleDeploy = (e) => {
    e.preventDefault();    
    let roundedPrice = formData.initialPrice;
    let tick = formData.tick;
    if (!formData.isInitialPriceRounded) {
      const roundedData = roundToClosestTickPrice(roundedPrice, formData.poolFee);
      roundedPrice = roundedData.roundedPrice;
      tick = roundedData.tick;
    }
    // Call onDeploy:
    onDeploy({
      tokenName: formData.tokenName,
      tokenSymbol: formData.tokenSymbol,
      initialSupply: formData.initialSupply,
      initialPrice: roundedPrice,
      poolFee: formData.poolFee,
      tick: tick,
    });
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Deploy your own meme token"></CardHeader>
          <form onSubmit={handleDeploy}>
            <CardContent>
              <Grid item container spacing={2} justifyContent="center">
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="tokenName"
                    name="tokenName"
                    label="Token Name"
                    variant="outlined"
                    autoComplete="off"
                    value={formData.tokenName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="tokenSymbol"
                    name="tokenSymbol"
                    label="Token Symbol"
                    variant="outlined"
                    autoComplete="off"
                    value={formData.tokenSymbol}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="initialSupply"
                    name="initialSupply"
                    label="Initial Supply"
                    variant="outlined"
                    autoComplete="off"
                    value={formData.initialSupply}
                    onChange={handleChange}
                    helperText={"Initial supply in token units (e.g. 100,000)"}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        decimalScale: 0,
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="initialPrice"
                    name="initialPrice"
                    label="Initial Price per token in ETH"
                    variant="outlined"
                    autoComplete="off"
                    value={formData.initialPrice}
                    onChange={handleChange}
                    onBlur={handleInitialPriceBlur}
                    helperText={"Initial price is rounded to closest tick"}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        decimalScale: 18,
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                disabled={!isValid || !userAddress || isDeploying}
                variant="contained"
                color="primary"
                type="Submit"
                className={classes.button}>
                Deploy
              </Button>
            </CardActions>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DeployTokenForm