import React from 'react';
import { useCallback, useContext, useEffect, useState, createContext } from 'react';
import { ethers } from 'ethers';
import { useWallet } from 'contexts/wallet';
import { MEMECOIN_DEPLOYER_ADDRESSES } from 'config';
import MEMECOIN_DEPLOYER_ABI from 'abis/MemecoinDeployer.json'

export const ContractsContext = createContext({
    memecoinDeployer: null
});

export const ContractsProvider = ({ children }) => {
  const { network, signer } = useWallet();
  const [memecoinDeployer, setMemecoinDeployer] = useState();

  const loadContract = useCallback(
    async () => {
      if (!network || !signer) return null;
      const address = MEMECOIN_DEPLOYER_ADDRESSES[network];
      if (!address) return null;
      return new ethers.Contract(address, MEMECOIN_DEPLOYER_ABI, signer);
    },
    [network, signer],
  );

  useEffect(() => {
    const load = async () => {
      if (!network || !signer) return;

      if (!memecoinDeployer) {
        const memecoinDeployer = await loadContract();
        setMemecoinDeployer(memecoinDeployer);
      }
    };
    load();
  }, [network, signer, memecoinDeployer, loadContract]);

  return (
    <ContractsContext.Provider
      value={{
        memecoinDeployer,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export function useContracts() {
  const context = useContext(ContractsContext);
  if (!context) {
    throw new Error('Missing Contracts context');
  }
  const {
    memecoinDeployer,
  } = context;

  return {
    memecoinDeployer,
  };
}
