import React from "react";
import { Container } from "@material-ui/core";

import DeployTokenForm from "components/DeployTokenForm";
import useMemecoinDeployer from "hooks/useMemecoinDeployer";

export default function DeployPage() {
  const { deploy, isDeploying } = useMemecoinDeployer();

  const onDeploy = (data) => {
    deploy(data);
  }

  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          paddingTop: "12vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DeployTokenForm onDeploy={onDeploy} isDeploying={isDeploying}/>
      </Container>
    </>
  );
}